import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, TableCell } from '@mui/material';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { RealogramDirectory, RealogramDirectorySearch } from 'types/realogram';
import { StoreBayTableMenu } from './storeBayTableMenu';

type Props = {
  realogramDirectory: RealogramDirectory | RealogramDirectorySearch;
};

export const StoreBayTableCells: FC<Props> = ({ realogramDirectory }) => {
  const overFlowSx = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px',
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { name } = realogramDirectory;

  const { isLarger } = useBreakpoint();

  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: realogramDirectory,
    isCandidate: false,
    isCan: true,
  });
  return (
    <>
      <TableCell
        sx={{
          ...overFlowSx,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '56px',
          width: '40px',
          padding: '0px 8px',
        }}
      >
        <ShelfIcon />
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          paddingLeft: '16px',
        }}
      >
        {/* TODO: add ソート番号 data here */}-
      </TableCell>
      <TableCell
        sx={{
          ...overFlowSx,
          paddingLeft: '32px',
          width: '100%',
          fontWeight: 600,
        }}
      >
        {name ?? '-'}
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        {/* TODO: add 商品部門 data here */}-
      </TableCell>
      <TableCell sx={{ paddingLeft: '32px' }}>
        {/* TOOD: add 売り場 data here */}-
      </TableCell>
      <TableCell sx={{ ...overFlowSx, paddingLeft: '32px' }}>
        <ActionVisible isCanUpdate={isCanUpdate} isCanRead={isCanRead} />
      </TableCell>
      {/* 個別にクリックイベントを設定するため */}
      <TableCell
        onClick={(e) => e.stopPropagation()}
        sx={{
          padding: '0px',
          position: 'sticky',
          right: 0,
          boxShadow: isLarger ? '' : '0px 2px 8px 0px #0000000D',
          background: theme.palette.white.primary,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ width: 24, height: 24 }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        </Box>
        <StoreBayTableMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        />
      </TableCell>
    </>
  );
};
