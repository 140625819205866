import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useStoreSectionsQuery } from '@reducers/shelfAppsApi';
import { Controller, UseFormReturn } from 'react-hook-form';

type FormValue = {
  storeSectionId?: string;
};

type Props<T extends FormValue> = {
  methods: T extends FormValue ? UseFormReturn<T> : never;
  onChange?: (storeSectionId: string) => void;
};

export const StoreSectionsSelect = <T extends FormValue>({
  methods,
  onChange,
}: Props<T>) => {
  const { data, isLoading } = useStoreSectionsQuery();
  const { control } = methods;

  return (
    <Controller
      control={control}
      name="storeSectionId"
      render={({ field, fieldState }) => (
        <FormControl
          size="small"
          fullWidth
          error={!!fieldState.error}
          disabled={isLoading}
        >
          <InputLabel>売り場</InputLabel>
          <Select
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e.target.value);
            }}
            name="storeSectionId"
            label="売り場"
          >
            {data?.store_sections?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {!!fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
