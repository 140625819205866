import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useProductDivisionsQuery } from '@reducers/shelfAppsApi';
import { Controller, UseFormReturn } from 'react-hook-form';

type FormValue = {
  productDivisionCode: string;
};

type Props<T extends FormValue> = {
  methods: T extends FormValue ? UseFormReturn<T> : never;
  onChange?: (value: string) => void;
};

export const ProductDivisionsSelect = <T extends FormValue>({
  methods,
  onChange,
}: Props<T>) => {
  const { data, isLoading } = useProductDivisionsQuery();
  const { control } = methods;

  return (
    <Controller
      control={control}
      name="productDivisionCode"
      render={({ field, fieldState }) => (
        <FormControl
          size="small"
          fullWidth
          error={!!fieldState.error}
          disabled={isLoading}
        >
          <InputLabel required>商品部門</InputLabel>
          <Select
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e.target.value);
            }}
            label="商品部門"
          >
            {data?.product_divisions?.map(({ product_division_code, name }) => (
              <MenuItem
                key={product_division_code}
                value={product_division_code}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
          {!!fieldState.error && (
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
