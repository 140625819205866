import { ProductDivisionsSelect } from '@components/organisms/createStoreBayModal/productDivisionsSelect';
import { StoreSectionsSelect } from '@components/organisms/createStoreBayModal/storeSectionsSelect';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';

type StoreBayConditionsProps = {
  storeSectionId: string;
  productDivisionCode: string;
};

export const StoreBayConditions = () => {
  const methods = useForm<StoreBayConditionsProps>({
    defaultValues: {
      storeSectionId: '',
      productDivisionCode: '',
    },
  });
  const [productDivisionCode, storeSectionId] = methods.watch([
    'productDivisionCode',
    'storeSectionId',
  ]);

  const onChangeProductDivision = (productDivisionCode: string) => {
    console.log(productDivisionCode);
  };
  const onChangeStoreSectionId = (storeSectionId: string) => {
    console.log(storeSectionId);
  };
  return (
    <Box component="div" display="flex" gap={2} my={2}>
      <Box component="div" width={320}>
        <ProductDivisionsSelect
          methods={methods}
          onChange={onChangeProductDivision}
        />
      </Box>
      <Box component="div" width={240}>
        <StoreSectionsSelect
          methods={methods}
          onChange={onChangeStoreSectionId}
        />
      </Box>
      <Button
        disabled={!productDivisionCode && !storeSectionId}
        onClick={() => {
          methods.reset();
        }}
      >
        すべて解除
      </Button>
    </Box>
  );
};
