import { ModalInnerProps } from '@components/modal';
import { RequiredText } from '@components/molecules/requiredText/requiredText';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InferType, object, string } from 'yup';
import { ModalActions } from '../modalActions/modalActions';
import { BayPlansSelect } from './bayPlansSelect';
import { ProductDivisionsSelect } from './productDivisionsSelect';
import { StoreSectionsSelect } from './storeSectionsSelect';

export type CreateCreateStoreBayModalProps = {
  onSubmit: (data: FormData) => void;
};
type Props = ModalInnerProps<CreateCreateStoreBayModalProps>;

//TODO: add required text
const schema = object({
  name: string()
    .required('required')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  name2: string()
    .required('required')
    .matches(
      /^(?![\s\u3000]*$).*/,
      '半角スペースまたは全角スペースのみで登録することはできません。'
    ),
  productDivisionCode: string().required('required'),
  storeSectionId: string(),
  bayPlanId: string(),
});
export type FormData = InferType<typeof schema>;

export const CreateStoreBayModal: FC<Props> = ({ handleClose }) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      name2: '',
      productDivisionCode: '',
      bayPlanId: '',
      storeSectionId: '',
    },
  });
  const onSubmit = (data: FormData) => {
    console.log(data);
  };
  return (
    <Box component="div">
      <RequiredText />
      <Box
        component="form"
        width={400}
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        mt={2}
      >
        <Stack direction="column" gap={2}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="number"
                name="name"
                size="small"
                fullWidth
                label="ソート番号"
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="name2"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                name="name2"
                size="small"
                fullWidth
                label="什器名"
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <ProductDivisionsSelect methods={methods} />
          <StoreSectionsSelect methods={methods} />
          <BayPlansSelect methods={methods} />
        </Stack>
        <ModalActions handleClose={handleClose} actionBtnTitle="追加" />
      </Box>
    </Box>
  );
};
