import { AppBar } from '@components/organisms';
import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { StoreBaySidebar } from './storeBaySidebar';
import { fullHeight, paths, SidebarValue } from '@utils/const';
import { useNavigate } from 'react-router-dom';

type Props = {
  sidebarValue: SidebarValue;
  isCreateStoreBayDisabled: boolean;
  handleCreateStoreBay: VoidFunction;
  handleClearSearch: VoidFunction;
};

export const Layout: FC<PropsWithChildren<Props>> = ({
  children,
  sidebarValue,
  isCreateStoreBayDisabled,
  handleCreateStoreBay,
  handleClearSearch,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      component="div"
      height={fullHeight}
      display="flex"
      flexDirection="column"
    >
      <AppBar title="什器一覧" />
      <Box component="div" display="flex" flex={1} overflow="hidden">
        <StoreBaySidebar
          isCreateStoreBayDisabled={isCreateStoreBayDisabled}
          sidebarValue={sidebarValue}
          handleCreateStoreBay={handleCreateStoreBay}
          onClickTabAll={() => {
            handleClearSearch();
            navigate(paths.storeBays.folders);
          }}
          onClickTabFavorite={() => {
            handleClearSearch();
            navigate(paths.storeBays.foldersStarred);
          }}
        />
        <Box component="div" flex={1} px={3} py={2} display="flex">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
