import { ProductFlagChip } from '@components/molecules/productFlagChip/productFlagChip';
import {
  Box,
  Divider,
  styled,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { productTags } from '@utils/const';
import { FC, forwardRef } from 'react';
import { theme } from '../../../theme';

type Props = {
  tags: string[];
  handleClick: (tag?: string) => void;
  isDisabled: boolean;
  isSalesEnded: boolean;
  handleChangeSalesEnded: () => void;
  isLoading: boolean;
};

export const resetChipLabel = 'すべて';

export const ProductFlagChips: FC<Props> = ({
  handleClick,
  tags,
  isDisabled,
  isSalesEnded,
  handleChangeSalesEnded,
  isLoading,
}) => {
  return (
    <StyledTabs
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      value={0}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        textAlign="center"
        alignItems="center"
      >
        <Typography variant="body2" color={theme.palette.textBlack.secondary}>
          推奨取消の商品
        </Typography>
        <Switch
          checked={isSalesEnded}
          onChange={handleChangeSalesEnded}
          disabled={isLoading}
        />
        <Divider orientation="vertical" />
      </Box>
      <Tab
        value={0}
        component={forwardRef(function ProductChip() {
          return (
            <Box component="div">
              <ProductFlagChip
                label={resetChipLabel}
                isSelected={tags.length === 0}
                handleClick={handleClick}
                isDisabled={isDisabled}
              />
            </Box>
          );
        })}
      />

      {productTags.map((tag, index) => (
        <Tab
          key={`tag-${tag}`}
          value={index + 1}
          component={forwardRef(function ProductChip() {
            return (
              <ProductFlagChip
                label={tag}
                isSelected={tags.includes(tag)}
                handleClick={handleClick}
                isDisabled={isDisabled}
              />
            );
          })}
        />
      ))}
    </StyledTabs>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
export const StyledTabs = styled(Tabs)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '& .MuiTabs-flexContainer': {
    gap: '8px',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },

  '& .MuiTabScrollButton-root': {
    position: 'absolute',
    top: 0,

    width: 48,
    height: '100%',
    background: theme.palette.white.primary,
    boxShadow: '-2px 0px 8px rgba(0, 0, 0, 0.1)',
    opacity: 1,
    zIndex: 2,

    '&:first-of-type': {
      left: '-8px',
    },

    '&:last-child': {
      right: '-8px',
    },

    '&.Mui-disabled': {
      opacity: 0,
    },

    '& svg': {
      fill: '#757575',
      width: 30,
      height: 30,
    },
  },
}));
