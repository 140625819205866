import { GoBackButton } from '@components/molecules/goBackButtonIcon/goBackButton';
import { PlanogramInfoDialog } from '@components/molecules/planogramInfoDialog/planogramInfoDialog';
import { PlanogramDeleteDialog } from '@components/organisms';
import { ClonePlanogramModalProps } from '@components/organisms/clonePlanogramForm/clonePlanogramForm';
import { ViewModeToggle } from '@components/organisms/viewModeToggle/viewModeToggle';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useComparisonItemModal } from '@hooks/useComparisonItemModal';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { useModal } from '@hooks/useModal';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { InfoOutlined } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  updatePlanogramSearch,
  updateRealogramSearch,
} from '@reducers/comparisonItemModal';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import { setSelectedItemIndex } from '@reducers/planogram';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import {
  setDirectoryId,
  setDirectoryType,
  setPlanogramDirectoryParent,
} from '@reducers/sharePermission';
import {
  useClosePlanogramMutation,
  useDeletePlanogramMutation,
  useGetPlanogramDirectoryQuery,
  useGetStoreQuery,
  useListPlanogramDirectoriesQuery,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { paths, toastMessages } from '@utils/const';
import { isPlanogramBucketPlan, isPlanogramPlan } from '@utils/planogram';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { OrganizationStatus, Planogram } from 'types/planogram';
import { Header } from '../header/header';
import { MoreButton } from './fragments/moreButton';
import { ViewOnlyChip } from './fragments/viewOnlyChip';
import { updateIsShowProductDetail } from '@reducers/planogramEditor/reducer';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { ModeToggle, ViewToggle } from '../viewModeToggle/fragments';
import { RoleIcon } from '@components/molecules/roleIcon/roleIcon';

type Props = {
  planogram?: Planogram;
  handleChangeMode: (value: ShelfDetailMode) => void;
  handleChangeView: (value: ShelfDetailView) => void;
  hiddenElement: boolean;
  isShowAnalyticsButton?: boolean;
  organizationStatus: OrganizationStatus | undefined;
};

export const PlanogramDetailHeader: FC<Props> = ({
  planogram,
  handleChangeMode,
  handleChangeView,
  hiddenElement,
  isShowAnalyticsButton,
  organizationStatus,
}) => {
  const { detailView: view, detailMode: mode } = useAppSelector(
    selectPlanogramEditorState
  );
  const [sessionLastVisitedPlanogramListURL] = useSessionStorage(
    'sessionLastVisitedPlanogramListURL',
    paths.plans.folders
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [planogramInfoOpen, setPlanogramInfoOpen] = useState(false);
  const navigate = useNavigate();
  const planogramId = planogram?.id;
  const dispatch = useAppDispatch();
  const [deletePlanogram] = useDeletePlanogramMutation();
  const [clonePlanogram] = useClosePlanogramMutation();
  const { resetComparisonItemModalState } = useComparisonItemModal();
  const { bayPlanCode } = useBayPlanCodes(planogram?.bay_plan_code_id);
  const { lastVisitedPlanogramListURL } = useAppSelector(
    (state) => state.Planogram
  );
  const { showModal: showClonePlanogramModal } =
    useModal<ClonePlanogramModalProps>('clonePlanogram', '棚割を複製');
  const { showLicenseModal } = useSharePermissionModal();
  const { data: storeBayPlan } = useGetStoreQuery(
    {
      storeId: planogram?.store_id ?? 0,
    },
    { skip: !planogram?.store_id }
  );
  const { data: planogramDirectory } = useGetPlanogramDirectoryQuery(
    {
      id: planogram?.directory_id ?? '',
    },
    { skip: !planogram?.directory_id }
  );
  const { data: directory } = useListPlanogramDirectoriesQuery(
    {
      id: planogram?.directory_parent_id,
    },
    { skip: !planogram?.directory_parent_id }
  );
  const bayPlanCodeGap = 1.5;

  const planogramType = useMemo(() => {
    if (isPlanogramPlan(planogram?.plan)) {
      return 'shelves';
    } else if (isPlanogramBucketPlan(planogram?.plan)) {
      return 'buckets';
    }
    return undefined;
  }, [planogram?.plan]);

  const handleDeletePlanogram = async () => {
    setDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      if (!planogramId) return;
      await deletePlanogram({
        planogramId,
      }).unwrap();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deletePlanogram,
        })
      );
      navigate(paths.plans.folders);
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deletePlanogram,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };
  //TODO: common with planogram table
  const handleClonePlanogram = () => {
    if (!planogramId) return;
    showClonePlanogramModal({
      name: `${planogram?.name ?? ''}（コピー）`,
      bayPlanId: planogram?.bay_plan_id,
      onSubmit: async ({ name, bayPlanId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          const result = await clonePlanogram({
            name,
            originPlanogramId: planogramId,
            bayPlanId,
            directoryParentId: planogram.directory_parent_id,
          }).unwrap();
          dispatch(setSelectedItemIndex(0));
          navigate(paths.plans.edit(result.planogram.id));
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.clonePlanogram,
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
      type: planogramType,
    });
  };

  const handleClickBack = () => {
    resetComparisonItemModalState();
    navigate(
      lastVisitedPlanogramListURL
        ? lastVisitedPlanogramListURL
        : sessionLastVisitedPlanogramListURL
    );
  };

  const handleClickMemo = () => {
    // Todo: メモボタン押下時の挙動追加
  };

  const handleSharePermission = () => {
    if (!planogram?.directory_id) return;
    dispatch(setDirectoryId(planogram?.directory_id));
    dispatch(setDirectoryType('planogram'));
    dispatch(setPlanogramDirectoryParent(directory?.parent));
    showLicenseModal({
      type: 'file',
    });
  };

  const resetSearchInputInComparisonModal = () => {
    dispatch(updatePlanogramSearch(undefined));
    dispatch(updateRealogramSearch(undefined));
    dispatch(updateIsShowProductDetail(false));
  };

  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogram,
    isPlanogram: true,
    isCan: true,
  });
  const { isLarger } = useBreakpoint();

  return (
    <>
      {isLarger ? (
        <Header>
          {!hiddenElement && (
            <GoBackButton
              width={40}
              height={40}
              handleClick={handleClickBack}
            />
          )}
          <Box
            component="div"
            display="flex"
            alignItems="center"
            flexGrow={1}
            gap={2}
          >
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap={0.5}
            >
              <Typography
                lineHeight="18px"
                variant="subtitle1"
                sx={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitLineClamp: 1,
                  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                  WebkitBoxOrient: 'vertical',
                }}
                textOverflow="ellipsis"
              >
                {planogram?.name}
              </Typography>
              <Box
                component="div"
                display="flex"
                alignItems="center"
                gap={!bayPlanCode?.name ? 0 : bayPlanCodeGap}
              >
                <Typography
                  variant="body3"
                  sx={{
                    color: theme.palette.textBlack.secondary,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                    WebkitLineClamp: 1,
                    // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                    WebkitBoxOrient: 'vertical',
                  }}
                  textOverflow="ellipsis"
                  lineHeight="12px"
                >
                  {bayPlanCode?.name ?? ''}
                </Typography>
                {storeBayPlan?.store && (
                  <Typography
                    variant="body3"
                    textOverflow="ellipsis"
                    sx={{
                      color: theme.palette.textBlack.secondary,
                      overflow: 'hidden',
                      display: '-webkit-box',
                      // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                      WebkitLineClamp: 1,
                      // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                      WebkitBoxOrient: 'vertical',
                    }}
                    lineHeight="12px"
                  >
                    {storeBayPlan.store.name}
                  </Typography>
                )}
              </Box>
            </Box>
            {organizationStatus && (
              <Box
                component="div"
                sx={{
                  backgroundColor: theme.palette.backgroundBlack.active,
                  px: 1,
                  minHeight: '20px',
                  borderRadius: '2px',
                  gap: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                }}
              >
                <Typography
                  variant="caption1"
                  color={theme.palette.textBlack.secondary}
                  lineHeight="11px"
                  fontSize="11px"
                  noWrap
                >
                  {organizationStatus?.label}
                </Typography>
              </Box>
            )}
            <IconButton
              onClick={() => {
                setMenuOpen(false);
                setPlanogramInfoOpen(true);
              }}
              sx={{ p: 0, ml: '2px', mr: '18px' }}
            >
              <InfoOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>
          </Box>
          {!hiddenElement && (
            <>
              <ViewModeToggle
                mode={mode}
                view={view}
                scope={planogramDirectory?.planogram_directory.scope}
                isShowAnalyticsButton={isShowAnalyticsButton}
                handleChangeMode={handleChangeMode}
                handleChangeView={handleChangeView}
                handleSharePermission={handleSharePermission}
              />
              <MoreButton
                menuOpen={menuOpen}
                planogram={planogram}
                handleOpenMenu={() => setMenuOpen(true)}
                handleCloseMenu={() => setMenuOpen(false)}
                handleClickMemoButton={handleClickMemo}
                handleClickDeleteButton={() => {
                  setMenuOpen(false);
                  setDialogOpen(true);
                }}
                handleClickCloneButton={() => {
                  setMenuOpen(false);
                  handleClonePlanogram();
                }}
              />
            </>
          )}
          {planogram && !hiddenElement && (
            <>
              {isCanUpdate ? (
                <Button
                  onClick={() => {
                    resetSearchInputInComparisonModal();
                    planogramId && navigate(paths.plans.edit(planogramId));
                  }}
                  variant="contained"
                >
                  編集
                </Button>
              ) : (
                <ViewOnlyChip />
              )}
            </>
          )}
        </Header>
      ) : (
        <AppBar
          position="static"
          sx={{
            borderBottom: `1px solid ${theme.palette.backgroundBlack.light}`,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
            p: 0, //NOTE: cleanTheme設定によってappBarの上に要素が追加されてしまうため削除
            ':after': { display: 'none' },
            height: '100px !important',
          }}
        >
          <Toolbar>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              flex={1}
              py="8px"
            >
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Box component="div" display="flex" alignItems="center">
                  {!hiddenElement && (
                    <GoBackButton
                      width={40}
                      height={40}
                      handleClick={handleClickBack}
                    />
                  )}
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="row"
                    gap={1}
                  >
                    <Typography
                      lineHeight="18px"
                      variant="subtitle1"
                      sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                        WebkitLineClamp: 1,
                        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                        WebkitBoxOrient: 'vertical',
                      }}
                      textOverflow="ellipsis"
                      height="15px"
                    >
                      {planogram?.name}
                    </Typography>
                    <Box
                      component="div"
                      display="flex"
                      alignItems="center"
                      flexWrap="nowrap"
                      gap={!bayPlanCode?.name ? 0 : bayPlanCodeGap}
                    >
                      <Typography
                        variant="body3"
                        sx={{
                          color: theme.palette.textBlack.secondary,
                          overflow: 'hidden',
                          display: '-webkit-box',
                          // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                          WebkitLineClamp: 1,
                          // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                          WebkitBoxOrient: 'vertical',
                        }}
                        textOverflow="ellipsis"
                        lineHeight="12px"
                      >
                        {bayPlanCode?.name ?? ''}
                      </Typography>
                      {storeBayPlan?.store && (
                        <Typography
                          variant="body3"
                          textOverflow="ellipsis"
                          sx={{
                            color: theme.palette.textBlack.secondary,
                            overflow: 'hidden',
                            display: '-webkit-box',
                            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                            WebkitLineClamp: 1,
                            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                            WebkitBoxOrient: 'vertical',
                            minWidth: '80px',
                          }}
                          lineHeight="12px"
                        >
                          {storeBayPlan.store.name}
                        </Typography>
                      )}
                    </Box>
                    {organizationStatus && (
                      <Box
                        component="div"
                        sx={{
                          backgroundColor: theme.palette.backgroundBlack.active,
                          px: 1,
                          minHeight: '20px',
                          borderRadius: '2px',
                          gap: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                        }}
                      >
                        <Typography
                          variant="caption1"
                          color={theme.palette.textBlack.secondary}
                          lineHeight="11px"
                          fontSize="11px"
                          noWrap
                        >
                          {organizationStatus?.label}
                        </Typography>
                      </Box>
                    )}
                    <IconButton
                      onClick={() => {
                        setMenuOpen(false);
                        setPlanogramInfoOpen(true);
                      }}
                      sx={{ p: 0, ml: '2px', mr: '18px' }}
                    >
                      <InfoOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </IconButton>
                  </Box>
                </Box>
                {planogram && !hiddenElement && (
                  <Box component="div" display="flex" flexDirection="row">
                    <Button
                      onClick={handleSharePermission}
                      sx={{
                        px: 0,
                        '&.MuiButton-root': {
                          border: `1px solid ${theme.palette.dividerBlack.dark}`,
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.backgroundBlack.hover,
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                        },
                        ...{
                          backgroundColor: 'transparent',
                        },
                        minWidth: '40px',
                      }}
                      variant="outlined"
                    >
                      <RoleIcon
                        option={planogramDirectory?.planogram_directory.scope}
                        sx={{ width: '20px', height: '20px', maxWidth: '20px' }}
                      />
                    </Button>
                    <MoreButton
                      menuOpen={menuOpen}
                      planogram={planogram}
                      handleOpenMenu={() => setMenuOpen(true)}
                      handleCloseMenu={() => setMenuOpen(false)}
                      handleClickMemoButton={handleClickMemo}
                      handleClickDeleteButton={() => {
                        setMenuOpen(false);
                        setDialogOpen(true);
                      }}
                      handleClickCloneButton={() => {
                        setMenuOpen(false);
                        handleClonePlanogram();
                      }}
                    />
                    {isCanUpdate ? (
                      <Button
                        onClick={() => {
                          resetSearchInputInComparisonModal();
                          planogramId &&
                            navigate(paths.plans.edit(planogramId));
                        }}
                        variant="contained"
                      >
                        編集
                      </Button>
                    ) : (
                      <ViewOnlyChip />
                    )}
                  </Box>
                )}
              </Box>
              <Box component="div" display="flex" gap={1} px="56px">
                <ViewToggle
                  onChange={handleChangeView}
                  value={view}
                  isActual={false}
                  isShowAnalyticsButton={isShowAnalyticsButton}
                />
                <ModeToggle onChange={handleChangeMode} value={mode} />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <PlanogramDeleteDialog
        open={dialogOpen}
        handleDeleteShelfEditorItem={() => handleDeletePlanogram()}
        handleCloseDialog={() => setDialogOpen(false)}
      />
      <PlanogramInfoDialog
        planogram={planogram}
        open={planogramInfoOpen}
        onClose={() => setPlanogramInfoOpen(false)}
      />
    </>
  );
};
