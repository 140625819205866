import { Create, Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { FC } from 'react';

type Props = {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};

export const StoreBayTableMenu: FC<Props> = ({ anchorEl, handleClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <MenuItem onClick={() => void 0} disabled={false}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>削除</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => void 0} disabled={false}>
        <ListItemIcon>
          <Create />
        </ListItemIcon>
        <ListItemText>編集</ListItemText>
      </MenuItem>
    </Menu>
  );
};
