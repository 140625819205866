import { paths } from 'types/api';
import { baseApi, KeysToCamelcase } from '../baseApi';

export type GetStoresParams = KeysToCamelcase<
  paths['/api/v1/stores']['get']['parameters']['query'] & {
    filter_by_role?: string;
  } // TODO: delete this intersection when api.ts is regenrated
>;
export type GetStoresResponse =
  paths['/api/v1/stores']['get']['responses']['200']['content']['application/json'];
type GetStoreParams = KeysToCamelcase<
  paths['/api/v1/stores/{store_id}']['get']['parameters']['path']
>;
export type GetStoreResponse =
  paths['/api/v1/stores/{store_id}']['get']['responses']['200']['content']['application/json'];

type ListStoreBaysParams = KeysToCamelcase<
  paths['/api/v1/stores/{store_id}/store_bays']['get']['parameters']['path']
>;
export type ListStoreBaysResponse =
  paths['/api/v1/stores/{store_id}/store_bays']['get']['responses']['200']['content']['application/json'];

type ProductDivisionsResponse =
  paths['/api/v1/product_divisions']['get']['responses']['200']['content']['application/json'];

type StoreSectionsResponse =
  paths['/api/v1/store_sections']['get']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/stores
const storesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listStores: builder.query<GetStoresResponse, GetStoresParams>({
      query: (params) => ({
        url: 'stores',
        params: {
          offset: params?.offset,
          limit: params?.limit,
          filter_by_role: params?.filterByRole,
        },
      }),
    }),
    getStore: builder.query<GetStoreResponse, GetStoreParams>({
      query: (params) => ({
        url: `stores/${params.storeId}`,
      }),
    }),
    listStoreBays: builder.query<ListStoreBaysResponse, ListStoreBaysParams>({
      query: (params) => ({
        url: `stores/${params.storeId}/store_bays`,
      }),
    }),
    productDivisions: builder.query<ProductDivisionsResponse, void>({
      query: () => ({
        url: `product_divisions`,
      }),
    }),
    storeSections: builder.query<StoreSectionsResponse, void>({
      query: () => ({
        url: `store_sections`,
      }),
    }),
  }),
});

export const {
  useListStoresQuery,
  useLazyListStoresQuery,
  useGetStoreQuery,
  useListStoreBaysQuery,
  useProductDivisionsQuery,
  useStoreSectionsQuery,
} = storesApi;
