import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { FilterButton } from '@components/molecules/filterButton/filterButton';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { useModal } from '@hooks/useModal';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { Box, Typography } from '@mui/material';
import { selectUser } from '@reducers/auth/selectors';
import {
  useListRealogramDirectoriesQuery,
  useSearchRealogramDirectoriesQuery,
} from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import {
  getListPageTitle,
  pageTitle,
  paths,
  rowsPerPage,
  SidebarValue,
} from '@utils/const';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  RealogramDirectory,
  RealogramListOrder,
  RealogramOrder,
} from 'types/realogram';
import { SearchText } from '../planograms/fragments/searchText';
import { getColumns } from '../realograms/fragments/realogramsDirectoryTable/directoryTableHeaderData';
import { TableCells } from '../realograms/fragments/realogramsDirectoryTable/tableCells';
import { TableHeader } from '../realograms/fragments/realogramsDirectoryTable/tableHeader';
import { Layout } from './fragments/layout';
import { StoreBayConditions } from './fragments/storeBayConditions';
import { StoreBayTableCells } from './fragments/storeBayTableCells';
import { getStoreBayColumns } from './fragments/storeBayTableHeaderData';
import { DataTable } from './fragments/table';
import { TableRowWrapper } from './fragments/tableRowWrapper';

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (isRoot) {
    return 'スキャン結果';
  } else {
    return directoryParentName;
  }
};

type Props = {
  sidebarValue?: SidebarValue;
};

export const StoreBays: FC<Props> = ({ sidebarValue = 'all' }) => {
  const navigate = useNavigate();
  const { updateQueryParameter, removeQueryParameters, existingParams } =
    useUrlQueryParams();
  const params = useParams<{ id: string }>();
  const directoryId = params.id ?? '';
  const [search, setSearch] = useState('');
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const firstOrder = existingParams.first_order as RealogramOrder;
  const isFilteredByFavorite = sidebarValue === 'favorite';
  const user = useAppSelector(selectUser);
  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
  } = useListRealogramDirectoriesQuery(
    {
      id: directoryId,
      firstOrder: firstOrder as RealogramOrder,
    },
    {
      skip: !!search || isFilteredByFavorite,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
  } = useSearchRealogramDirectoriesQuery(
    {
      offset: 0,
      limit: rowsPerPage,
      name: search,
      firstOrder: firstOrder as RealogramOrder,
      favoriteOwnerId:
        isFilteredByFavorite && user?.id ? [user?.id] : undefined,
    },
    {
      skip: !search && !isFilteredByFavorite,
      refetchOnMountOrArgChange: true,
    }
  );
  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: directory?.parent,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: directory?.parent,
    isCandidate: false,
    isCan: true,
  });

  const handleSearch = (value: string) => {
    setSearch(value);
    updateQueryParameter('name', value);
    if (isFilteredByFavorite) {
      navigate(paths.storeBays.folders, { replace: true });
    }
  };
  const clearSearchText = () => {
    setIsClearSearch(true);
    setSearch('');
    removeQueryParameters(['name']);
  };
  const { showModal: showCreateStoreBayModal } = useModal(
    'createStoreBayModal',
    '什器追加'
  );
  const handleClickDirectory = (item: RealogramDirectory) => {
    if (search) {
      setSearch('');
    }
    const directoryId = item.id;
    navigate(paths.storeBays.foldersId(directoryId));
  };

  const handleClickFile = (index: number, item: RealogramDirectory) => {
    navigate(paths.actuals.id(String(item.realogram_candidate_id)));
  };

  const handleRowClick = (index: number, item: RealogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);
    } else if (item.type === 'file' && item.realogram_candidate_id) {
      handleClickFile(index, item);
    }
  };

  const handleClickMoveParentDirectory = () => {
    const parentId = directory?.parent.parent_id;
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;
    navigate(paths.storeBays.foldersId(directoryId));
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    navigate(paths.storeBays.foldersId(directoryId));
  };

  const handleShowFilters = () => {
    setIsShowFilters(!isShowFilters);
  };

  const handleChangeOrder = (order?: RealogramListOrder) => {
    if (!order) return removeQueryParameters(['first_order']);
    updateQueryParameter('first_order', order ?? '');
  };

  const tableData =
    search || isFilteredByFavorite
      ? searchedData?.realogram_directories
      : directory?.realogram_directories;
  const isShowBreadcrumbList = !!directory && !!directoryId;
  const isRoot = directory?.parent.type === 'root';

  const shouldChangeColor = isShowFilters;
  const filterButtonTextColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.textBlack.primary;
  const filterButtonIconColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.icons.primary;
  const isLoading = isDirectoryLoading || isSearchLoading;
  const hasStoreId = !!directory?.parent.store_id;
  const isApiLoading =
    isDirectoryLoading ||
    isDirectoryFetching ||
    isSearchLoading ||
    isSearchFetching;
  const columns = hasStoreId ? getStoreBayColumns() : getColumns(false);
  return (
    <Layout
      sidebarValue={sidebarValue}
      handleCreateStoreBay={showCreateStoreBayModal}
      handleClearSearch={clearSearchText}
      isCreateStoreBayDisabled={!hasStoreId}
    >
      <Box
        component="div"
        sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <SearchText
          searchText={search}
          isClearSearch={isClearSearch}
          setIsClearSearch={setIsClearSearch}
          handleSearch={handleSearch}
          clearSearch={clearSearchText}
          placeholder="店舗を検索"
        />
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={-1}
          mt={2}
        >
          {isShowBreadcrumbList ? (
            <Box component="div">
              {!isRoot && (
                <Box component="div" marginLeft={5}>
                  <BreadcrumbList
                    breadcrumbs={directory.breadcrumb}
                    parentDirectory={directory.parent}
                    screenType="Main"
                    fontSize="12px"
                    handleClickBreadcrumbs={handleClickBreadcrumbs}
                    directoryRootName="スキャン結果"
                  />
                </Box>
              )}
              <Box component="div" display="flex" alignItems="center">
                {!isRoot && (
                  <GoParentDirectoryButton
                    width={32}
                    height={32}
                    handleClick={handleClickMoveParentDirectory}
                  />
                )}
                <Typography
                  fontSize="20px"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  mr={!isRoot ? '6px' : ''}
                >
                  {getDirectoryPageTitle('all', isRoot, directory.parent.name)}
                </Typography>
                {!isRoot && (
                  <ActionVisible
                    isCanUpdate={isCanUpdate}
                    isCanRead={isCanRead}
                  />
                )}
              </Box>
            </Box>
          ) : (
            <Typography fontWeight="bold" fontSize="20px">
              {getListPageTitle({
                defaultTitle: pageTitle.realogram,
                sidebarValue: sidebarValue,
                isSearched: !!search,
              })}
            </Typography>
          )}
          <Box component="div" display="flex" gap={1}>
            <FilterButton
              onClick={handleShowFilters}
              textColor={filterButtonTextColor}
              iconColor={filterButtonIconColor}
            />
          </Box>
        </Box>
        {isShowFilters && <StoreBayConditions />}
        {/* TABLE */}
        <Box component="div" mt={2} flex={1}>
          <DataTable<RealogramDirectory>
            isEmpty={!tableData?.length && !isLoading}
            isDisplayLoadingSkeleton={
              // TODO: add offset
              // offset === 0 &&
              true && isApiLoading
            }
            data={tableData ?? []}
            columns={columns}
            handleRowClick={handleRowClick}
            tableHeaderContent={
              <TableHeader
                columns={columns}
                firstOrder={firstOrder}
                handleChangeOrder={(order?: RealogramListOrder) =>
                  handleChangeOrder(order)
                }
              />
            }
            itemContent={(_, item) => {
              return hasStoreId ? (
                <StoreBayTableCells realogramDirectory={item} />
              ) : (
                <TableCells
                  isFilteredByViewed={false}
                  realogramDirectory={item}
                  handleOpenDeleteDialog={() => void 0}
                  handleFavoriteClick={() => void 0}
                  handleSharePermission={() => void 0}
                />
              );
            }}
            renderTableRow={(props) => {
              return (
                <TableRowWrapper
                  handleRowClick={() =>
                    // eslint-disable-next-line react/prop-types -- prop types is outdated
                    handleRowClick(props['data-index'], props.item)
                  }
                  {...props}
                />
              );
            }}
          />
        </Box>
      </Box>
    </Layout>
  );
};
