import { BottleAndCanIcon } from '@components/molecules/bottleAndCanIcon/bottleAndCan';
import { ProductIcon } from '@components/molecules/productIcon/productIcon';
import { ShelfEditorIcon } from '@components/molecules/shelfEditorIcon/shelfEditorIcon';
import { ShelfIcon } from '@components/molecules/shelfIcon/shelfIcon';
import { ShelfIconPlus } from '@components/molecules/shelfIconPlus/shelfIconPlus';
import { AppBar } from '@components/organisms';
import { ScanPermissionDialog } from '@components/organisms/scanPermissionDialog/scanPermissionDialog';
import { useCreatePlanogram } from '@hooks/useCreatePlanogram';
import { usePageTitle } from '@hooks/usePageTitle';
import { CameraAltOutlined, PeopleOutlined } from '@mui/icons-material';
import { Box, Button, List, Typography } from '@mui/material';
import { useAppSelector } from '@store/index';
import { paths } from '@utils/const';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { PrimaryNavigationButton } from './fragments/primaryNavigationButton';
import { SecondaryNavigationButton } from './fragments/secondaryNavigationButton';
import { useNavigateToScanner } from './hooks/useNavigateToScanner';

const displayStoreBays = false;

export const Home: FC = () => {
  usePageTitle();
  const {
    navigateToScanner,
    isPermissionDialogOpen,
    setIsPermissionDialogOpen,
  } = useNavigateToScanner();
  const handleCreatePlanogram = useCreatePlanogram();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.Auth);

  return (
    <>
      <AppBar title="棚アプリ" />
      <Box
        component="div"
        display={{ sm: 'flex' }}
        justifyContent="center"
        mt={4}
        mx={{ xs: 2 }}
      >
        <List>
          <Box component="div" display={{ sm: 'flex' }}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              mr={{ sm: 1 }}
              textAlign={{ xs: 'center' }}
            >
              <PrimaryNavigationButton
                onClickAction={() => navigate(paths.actuals.viewList)}
                icon={<ShelfEditorIcon sx={{ fontSize: 40, my: 3 }} />}
              >
                棚割実績一覧
                <br />
                （棚スキャナ）
              </PrimaryNavigationButton>
              <SecondaryNavigationButton
                onClickAction={navigateToScanner}
                title="新規スキャン"
                icon={
                  <CameraAltOutlined
                    color="primary"
                    sx={{ fontSize: 24, mr: 2, my: 2 }}
                  />
                }
              />
            </Box>
            <Box component="div" display="flex" flexDirection="column" ml={1}>
              <PrimaryNavigationButton
                onClickAction={() => navigate(paths.plans.root)}
                icon={<ProductIcon sx={{ fontSize: 40, my: 3 }} />}
              >
                棚割計画一覧
                <br />
                （棚エディタ）
              </PrimaryNavigationButton>
              <SecondaryNavigationButton
                onClickAction={handleCreatePlanogram}
                title="新規棚割"
                icon={
                  <ShelfIconPlus
                    color="primary"
                    sx={{ fontSize: 24, mr: 2, my: 2 }}
                  />
                }
                sx={{
                  display: { xs: 'none', sm: 'inline-flex' },
                }}
              />
            </Box>
          </Box>
          <Box
            component="div"
            textAlign="center"
            justifyContent="center"
            mt={6}
            display={{ xs: 'none', sm: 'flex' }}
            gap={2}
          >
            <Button
              variant="outlined"
              sx={{
                width: 280,
                padding: 0,
                borderColor: theme.palette.dividerBlack.dark,
              }}
              component={Link}
              to={paths.products.root}
            >
              <BottleAndCanIcon
                color="primary"
                sx={{ fontSize: 24, mr: 2, my: 2 }}
              />
              <Typography
                variant="subtitle1"
                color={theme.palette.textBlack.primary}
                component="div"
                lineHeight={1}
              >
                商品一覧
              </Typography>
            </Button>
            {user?.role === 'admin' && (
              <Button
                variant="outlined"
                sx={{
                  width: 280,
                  padding: 0,
                  borderColor: theme.palette.dividerBlack.dark,
                }}
                component={Link}
                to={paths.users.root}
              >
                <PeopleOutlined
                  sx={{
                    fontSize: 24,
                    mr: 2,
                    my: 2,
                    color: theme.palette.icons.primary,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.textBlack.primary}
                  component="div"
                  lineHeight={1}
                >
                  ユーザー管理
                </Typography>
              </Button>
            )}
            {/* TODO: Remove false after storeBays page is done */}
            {displayStoreBays && (
              <Button
                variant="outlined"
                sx={{
                  width: 280,
                  padding: 0,
                  borderColor: theme.palette.dividerBlack.dark,
                }}
                component={Link}
                to={paths.storeBays.home}
              >
                <ShelfIcon
                  color="primary"
                  sx={{
                    fontSize: 24,
                    mr: 2,
                    my: 2,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.textBlack.primary}
                  component="div"
                  lineHeight={1}
                >
                  什器管理
                </Typography>
              </Button>
            )}
          </Box>
          <Typography
            variant="body2"
            color={theme.palette.textBlack.secondary}
            display={{ xs: 'block', sm: 'none' }}
            mt={3}
          >
            スマートフォンは新規スキャンにのみ対応しています。
            <br />
            棚割実績・棚割計画はタブレットもしくはパソコンからご確認ください。
          </Typography>
        </List>
      </Box>
      <ScanPermissionDialog
        open={isPermissionDialogOpen}
        handleCancel={() => setIsPermissionDialogOpen(false)}
      />
    </>
  );
};
